import React, { useState } from "react"

import { PayItemsSection } from "components/admin-human-resources/pay-items-section"
import Container from "components/container"
import { DriveLayout } from "components/layout-custom"

const Page = () => {
  const [refreshCounter, setRefreshCounter] = useState(0)

  return (
    <DriveLayout title="Pay" padded={true}>
      <Container>
        <PayItemsSection
          sectionTitle="Additional Payments"
          audience="driver"
          showAddButton
          refreshCounter={refreshCounter}
          refreshFunc={() => setRefreshCounter(refreshCounter + 1)}
        />
      </Container>
    </DriveLayout>
  )
}

export default Page
